import { useState, useEffect } from 'react';
import { getDescription } from "../../_core/fetchData/getDescription";


/**
 * Building My Own CustomHooks
 * Documentation: https://en.reactjs.org/docs/hooks-custom.html
 * Hook used to extract data from useGetProjectDetail to fill the dashboard.
 * @param {string | undefined} projectId
 */
export function useGetProjectDetail(projectId: string | undefined) {
    const [dataDescription, setDataDescription] = useState('');
    const [dataMedia, setDataMedia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        try {
            setLoading(true);

            getDescription(projectId).then(
                res => {
                    if (res) {
                        setDataDescription(res.description);
                    }
                }).catch(() => {
                setError(true);
                setLoading(false);
            });

            const getMediaByRef = async (id: string | undefined) => {
                try {
                    const response: any = await fetch(`https://testapi-acjvideo.s3.eu-west-3.amazonaws.com/`);
                    const data = await response.text();
                    const parser = new DOMParser();
                    const xml = parser.parseFromString(data, "application/xml");
                    const media = xml.getElementsByTagName("Key");
                    const mediaArray: any = [];

                    for (let i = 0; i < media.length; i++) {
                        if (media[i].innerHTML.includes(`${ id }/Media`)) {
                            mediaArray.push(media[i].innerHTML);
                        }
                    }

                    return setDataMedia(mediaArray);

                } catch (error) {
                    return error;
                }
            }

            getMediaByRef(projectId).catch(() => {
                setError(true);
                setLoading(false);
            });

        } catch (e) {
            setError(true);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, [projectId]);

    return { dataDescription, error, loading, dataMedia };
}
