import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { apiRoutes } from "../../../_core/router/apiRoutes";
import WrappedRoutes from "../../../_common/component/WrappedRoutes";
import copyLink from "../../../assets/copy_default.svg";
import Button from "../../../_common/component/Button";
import { PageViews } from "@piwikpro/react-piwik-pro";
import useEffectOnce from "../../../_common/hooks/useEffectOnce";
import logoAirbus from '../../../assets/AIRBUS-Copper.svg';


const Success = () => {
    let { id } = useParams();
    const href = `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_ROOT_PATH}${id}`;
    const [routeMatch, setRouteMatch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffectOnce(() => {
        PageViews.trackPageView('Success page');
    });

    const onImageDownload = () => {
        const svg = document.getElementById("QRCode");
        // @ts-ignore
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            // @ts-ignore
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    useEffect(() => {
        setLoading(true);
        apiRoutes()
            .then(
                res => {
                    if (res && res.length > 0) {
                        // @ts-ignore
                        if (res.includes(id)) {
                            setLoading(false);
                            return setRouteMatch(true);
                        }
                    }
                    setLoading(false);
                    return setRouteMatch(false);
                }
            )
            .catch((err) => {
                console.error(err);
                setLoading(false);
                return setRouteMatch(false);
            })
    }, [id]);

    if (loading) {
        return <></>
    }
    if (routeMatch) {
        return (
            <>
                <div className="container-2">
                    <WrappedRoutes>
                        <div className='sp-success-container'>
                            <h1 className='h1 mr-0 centred'>QR Code</h1>

                            <QRCode id='QRCode' style={{ height: 190 }}
                                value={`${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_ROOT_PATH}${id}`} />

                            <div>
                                <Button id='download qr code' type="button" onClick={onImageDownload}
                                    disabled={false}>
                                    Download QR Code
                                </Button>
                            </div>

                            <div style={{ textAlign: 'center' }}>
                                <Link className='link-to'
                                    to={`${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_ROOT_PATH}${id}`}>{process.env.REACT_APP_DOMAIN}{process.env.REACT_APP_ROOT_PATH}{id}</Link>
                                <div className='slider-mui'>
                                    <img
                                        onClick={() => navigator.clipboard.writeText(href)}
                                        alt='logo upload media'
                                        src={copyLink}
                                        className='mr-1 copy-icon'
                                    />
                                    <p className='copy-link'>COPY LINK</p>
                                </div>
                            </div>
                        </div>
                    </WrappedRoutes>
                </div>
                <div className='container-airbus-fixed'>
                    <img
                        alt='logo logoAirbus-Cooper'
                        src={logoAirbus}
                    />
                </div>
            </>
        )
    } else {
        return <></>
    }
};

export default Success;
