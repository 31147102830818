import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from "react-router-dom";
import WrappedRoutes from "../../../_common/component/WrappedRoutes";
import Button from "../../../_common/component/Button";
import { PageViews } from "@piwikpro/react-piwik-pro";
import useEffectOnce from "../../../_common/hooks/useEffectOnce";
import logoAirbus from '../../../assets/AIRBUS-Copper.svg';

import CryptoJS from "crypto-js";

const Login = () => {
    let navigate = useNavigate();
    const [error, setError] = useState(false);
    const [input, setInput] = useState({
        id: '', password: ''
    });

    useEffectOnce(() => {
        PageViews.trackPageView('Login page');
    });

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        // Create and verify user's credentials
        if (!input.id ||
            !input.password ||
            !process.env.REACT_APP_NB_ITERATIONS ||
            !process.env.REACT_APP_KEY_LENGTH ||
            !process.env.REACT_APP_SALT ||
            !process.env.REACT_APP_IV ||
            !process.env.REACT_APP_PASSWORD_DECRYPTED ||
            !process.env.REACT_APP_USER_ENCRYPTED ||
            !process.env.REACT_APP_PASSWORD_ENCRYPTED) {
            setError(true);
            return;
        }
        const KEY = CryptoJS.PBKDF2(input.password, CryptoJS.enc.Hex.parse(process.env.REACT_APP_SALT), {
            keySize: parseInt(process.env.REACT_APP_KEY_LENGTH) / 32,
            iterations: parseInt(process.env.REACT_APP_NB_ITERATIONS)
        });
        const IV = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);
        const ID_ENCRYPTED = CryptoJS.AES.encrypt(input.id, KEY, {
            iv: IV,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        const PASSWORD_ENCRYPTED = CryptoJS.AES.encrypt(process.env.REACT_APP_PASSWORD_DECRYPTED, KEY, {
            iv: IV,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        if (ID_ENCRYPTED.toString() !== process.env.REACT_APP_USER_ENCRYPTED || PASSWORD_ENCRYPTED.toString() !== process.env.REACT_APP_PASSWORD_ENCRYPTED) {
            setError(true);
            return;
        }
        localStorage.setItem("userAcj", input.id);
        localStorage.setItem("pwdAcj", KEY.toString());
        setTimeout(() => {
            navigate("/upload");
        }, 500);
    };

    const handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setError(false);
        e.target.id === 'id'
            ? setInput({ ...input, id: e.target.value })
            : setInput({ ...input, password: e.target.value })
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="container-2">
                <WrappedRoutes>
                    <h1 className='h1 mb-5 centred'>Log in</h1>
                    <div className='sp-login-container'>
                        <div className='bottom-upload'>
                            <label className='input-label' htmlFor="label-id">ID</label>
                            <input
                                className='input'
                                id='id'
                                name='label-id'
                                type='text'
                                placeholder='Enter ID here'
                                onChange={handleChangeInput}
                                value={input.id}
                            />
                            <label className='input-label mt-4' htmlFor="label-password">PASSWORD</label>
                            <input
                                className='input'
                                id='password'
                                name='label-password'
                                type='password'
                                placeholder='******'
                                onChange={handleChangeInput}
                                value={input.password}
                            />
                            {error &&
                                <p style={{ fontSize: 16 }} className='media-error-typo mb-0'>
                                    invalid user id or password.
                                </p>
                            }
                        </div>
                        <Button type='submit' disabled={false}>Login</Button>
                    </div>
                </WrappedRoutes>
            </form>
            <div className='container-airbus-fixed'>
                <img
                    alt='logo logoAirbus-Cooper'
                    src={logoAirbus}
                />
            </div>
        </>
    )
}

export default Login;
