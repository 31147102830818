import { useEffect, useState } from 'react';
import { useGetProjectDetail } from "../../../_common/hooks/useGetProjectDetail";
import { getMediaByExtension } from "../../../_core/fetchData/getMediaByExtension";
import Button from "../../../_common/component/Button";
import Error from "../../../assets/error.svg";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { PageViews } from "@piwikpro/react-piwik-pro";
import useEffectOnce from "../../../_common/hooks/useEffectOnce";
import JSZip from 'jszip';
import ProgressBar from '../../../assets/loader.svg';
import { ProjectDetailProps } from '../../../_core/interfaces/interfaces';


const ProjectDetail = ({ projectId }: ProjectDetailProps) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { dataDescription, error, loading, dataMedia } = useGetProjectDetail(projectId);
    const [videoUrl, setVideoUrl] = useState<string[]>([]);
    const [pdfUrl, setPdfUrl] = useState<string[]>([]);
    const [downloading, setDownloading] = useState<boolean>(false);

    useEffectOnce(() => {
        PageViews.trackPageView('Project detail page');
    });

    function isMedia() {
        if (videoUrl.length === 0 && pdfUrl.length === 0) {
            return <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='media-error-box mt-4'>
                    <div className='title-and-icon'>
                        <img
                            style={{ marginRight: 11 }}
                            alt='logo valid media'
                            src={Error}
                        />
                        <p className='media-error-typo mr-0'>
                            Link is missing files
                        </p>
                    </div>
                </div>
            </div>
        }
    }

    function downloadAllVideos() {
        const zip = new JSZip();
        setDownloading(true);

        // Promises for downloading PDFs
        const pdfPromises = pdfUrl.map((urlPDF, index) => {
            const filename = urlPDF.slice(urlPDF.lastIndexOf('/') + 1);
            return fetch(urlPDF)
                .then(response => response.blob())
                .then(blob => {
                    zip.file(filename, blob);
                });
        });

        // Promises for downloading Videos
        const videoPromises = videoUrl.map((videoUrl, index) => {
            const filename = videoUrl.slice(videoUrl.lastIndexOf('/') + 1);
            return fetch(videoUrl)
                .then(response => response.blob())
                .then(blob => {
                    zip.file(filename, blob);
                });
        });


        Promise.all([...pdfPromises, ...videoPromises]).then(() => {
            setDownloading(false);
            zip.generateAsync({ type: 'blob' }).then(content => {
                const blobURL = URL.createObjectURL(content);
                const link = document.createElement('a');
                link.href = blobURL;
                link.download = 'ACJ_TT.zip';
                link.click();
            });
        });
    }

    function convertProjectId() {
        if (typeof projectId === "string") {
            const timeElapsed = parseInt(projectId);
            const today = new Date(timeElapsed);
            return today.toLocaleDateString()
        }
    }

    function handleDownloadVideo(v: string): void {
        try {
            const videoRequest = new Request(v);
            setDownloading(true);
            fetch(videoRequest)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('La requête de téléchargement a échoué.');
                    }
                    return response.blob();
                })
                .then((videoBlob) => {
                    const url = window.URL.createObjectURL(videoBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'acj_tt-media.mp4');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    setDownloading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setDownloading(false);
                });
        } catch (error) {
            console.error(error);
            setDownloading(false);
        }
    }

    function handleDownloadFile(v: string, fileName: string): void {
        try {
            const fileRequest = new Request(v);
            setDownloading(true);
            fetch(fileRequest)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('La requête de téléchargement a échoué.');
                    }
                    return response.blob();
                })
                .then((fileBlob) => {
                    const url = window.URL.createObjectURL(fileBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    setDownloading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setDownloading(false);
                });
        } catch (error) {
            console.error(error);
            setDownloading(false);
        }
    }

    useEffect(() => {
        if (dataMedia.length > 0) {
            getMediaByExtension(projectId, '.mp4', dataMedia)
                .then(r => {
                    // @ts-ignore
                    setVideoUrl(r);
                }).catch((error) => console.error(error))
            getMediaByExtension(projectId, '.pdf', dataMedia)
                .then(r => {
                    // @ts-ignore
                    setPdfUrl(r);
                }).catch((error) => console.error(error))
        }
    }, [dataMedia, projectId]);

    if (loading || error) {
        <></>
    }
    return (
        <>
            {downloading &&
                <div className='container-spinner'>
                    <img
                        style={{ height: 240 }}
                        alt='logo progress bar'
                        src={ProgressBar}
                    />
                </div>
            }
            <h1 className='h1'>
                Dear visitor,
            </h1>
            <div className='h1'>
                <p>We were delighted to welcome you in our ACJ premises and hope you had a great time with our team.</p>
                <p>Please kindly find below a PDF and video presenting your customised ACJ TwoTwenty cabin configuration.</p>
                <p>Feel free to share this URL or QR Code to your close relations.</p>
                <p>Please be reminded that this information is confidential and not suitable for external medias.</p>
            </div>
            <div style={{ marginTop: 60 }}>
                <p className='body1'>{dataDescription}</p>
            </div>
            {videoUrl.length > 0 && videoUrl.map((v, index) => {
                return (
                    <>
                        <div key={index + v}
                            className={'mt-4'}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <p className='join-media'>{v.slice(72)}</p>
                            <p className='join-media'>{"Created on the " + convertProjectId()}</p>
                        </div>
                        <video id={`video-url-${index}`} style={{ width: '100%' }} controls src={v}>Media</video>
                        <div style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <Button
                                    id='download video'
                                    onClick={() => handleDownloadVideo(v)}
                                    type="button"
                                    disabled={false}
                                >
                                    Download video
                                </Button>
                            </div>
                        </div>
                    </>
                )
            })}
            {pdfUrl.length > 0 &&
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    {pdfUrl.map((pdf, index) => {
                        const pdfName = pdf.slice(72);
                        return (
                            <>
                                <div style={{ height: '750px', marginTop: 100 }}>
                                    <div key={index + pdfName} className={'mt-4'}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                        <p className='join-media'>{pdfName}</p>
                                        <p className='join-media'>{"Created on the " + convertProjectId()}</p>
                                    </div>
                                    <Viewer
                                        fileUrl={pdf}
                                        plugins={[defaultLayoutPluginInstance]}
                                    />
                                </div>
                                <div style={{
                                    alignItems: 'flex-end',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    marginTop: 32
                                }}>
                                    <div>
                                        <Button
                                            id='download video'
                                            onClick={() => handleDownloadFile(pdf, pdfName)}
                                            type="button"
                                            disabled={false}
                                        >
                                            Download file
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </Worker>
            }
            {isMedia()}
            {!isMedia() && <>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginTop: 8
                    }}
                >
                    <div>
                        <Button
                            id='download files click'
                            onClick={downloadAllVideos} type="button" disabled={false}>
                            Download all files
                        </Button>
                    </div>
                </div>
            </>}
        </>
    )
};

export default ProjectDetail;
