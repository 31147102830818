import { bucketParams } from "../aws";
import { ListObjectsCommand, S3Client } from "@aws-sdk/client-s3";

import CryptoJS from "crypto-js";


export async function apiRoutes() {
    try {
        const pwdAcj = localStorage.getItem('pwdAcj');

        const KEY = CryptoJS.enc.Hex.parse(pwdAcj!);
        const IV = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV!);

        const clientAws = new S3Client({
                region: process.env.REACT_APP_AWS_REGION,
                credentials: {
                    accessKeyId: `${ CryptoJS.AES.decrypt(process.env.REACT_APP_AWS_KEY_ID_ENCRYPTED!, KEY, {
                        iv: IV,
                        padding: CryptoJS.pad.Pkcs7,
                        mode: CryptoJS.mode.CBC
                    }).toString(CryptoJS.enc.Utf8) }`,
                    secretAccessKey: `${ CryptoJS.AES.decrypt(process.env.REACT_APP_AWS_ACCESS_ENCRYPTED!, KEY, {
                        iv: IV,
                        padding: CryptoJS.pad.Pkcs7,
                        mode: CryptoJS.mode.CBC
                    }).toString(CryptoJS.enc.Utf8) }`
                }
            }
        );

        const response = await clientAws.send(new ListObjectsCommand(bucketParams));

        const eachFolder: object[] = [];

        response.Contents && response.Contents.forEach((item) => {
            const sliced = item.Key && item.Key.slice(0, 13);

            // @ts-ignore
            if (eachFolder.includes(sliced)) {
                return false;
            } else {
                // @ts-ignore
                eachFolder.push(sliced);
            }
        });

        return eachFolder;

    } catch (err) {
        console.error(err);
    }
}