import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import PiwikPro from '@piwikpro/react-piwik-pro';
import App from './_core/app/App';
import './_common/styles/_var.css';
import './_common/styles/modern-normalize.css';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

PiwikPro.initialize('f00039e4-0de7-4d2b-ad8d-23656401b847', 'https://airbus.containers.piwik.pro');

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
