export async function getMediaByExtension(id: string | undefined, extension: string, media: string[]) {
    try {
        const extensionSorted: string[] = [];

        media.forEach(el => {
            if (el.includes(extension)) {
                const sliced: string = el.slice(20);

                extensionSorted.push(`https://testapi-acjvideo.s3.eu-west-3.amazonaws.com/${ id }/Media/` + sliced);
            }
        });

        return extensionSorted;

    } catch (error) {
        return error;
    }
}
