import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route, useParams, Link, Navigate, useLocation } from "react-router-dom";
import ProjectDetail from '../../business/pages/ProjectDetail';
import WrappedRoutes from "../../_common/component/WrappedRoutes";
import Success from "../../business/pages/Success";
import Login from "../../business/pages/Login";
import img from '../../assets/BG_picture-2.jpg';
import logoAcj from '../../assets/ACJ-TwoTwenty.svg';
import logoAirbus from '../../assets/AIRBUS-Copper.svg';
import './App.css';

import CryptoJS from "crypto-js";

const Upload = lazy(() => import('../../business/pages/Upload'));

function App() {

    return (
        <>
            <div
                className='bg-big-picture'
                style={{ backgroundImage: `url(${img})` }}
            >
                <div className='container-logo'>
                    <img
                        alt='logo ACJ_TT'
                        style={{ height: 40 }}
                        src={logoAcj}
                    />
                </div>
                <Routes>
                    <Route path={`${process.env.REACT_APP_ROOT_PATH}upload`} element={
                        // @ts-ignore
                        <RequireAuth> <Upload /> </RequireAuth>} />
                    <Route path={`${process.env.REACT_APP_ROOT_PATH}:id`} element={<MatchPath />} />
                    <Route path={`${process.env.REACT_APP_ROOT_PATH}success/:id`} element={<RequireAuth><Success /></RequireAuth>} />
                    <Route path={`${process.env.REACT_APP_ROOT_PATH}login`} element={<Login />} />
                    <Route path="*" element={<Navigate to={`${process.env.REACT_APP_ROOT_PATH}upload`} />} />
                </Routes>
            </div>
        </>
    )
}

function MatchPath() {
    const [matchIs, setMatchIs] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    let { id } = useParams();

    useEffect(() => {
        setLoading(true);
        fetch(`https://testapi-acjvideo.s3.eu-west-3.amazonaws.com/`)
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const xml = parser.parseFromString(data, "application/xml");
                const media = xml.getElementsByTagName("Key");
                const mediaArray: any = [];

                for (let i = 0; i < media.length; i++) {
                    if (media[i].innerHTML.startsWith(`${id}/`)) {
                        mediaArray.push(media[i].innerHTML);
                    }
                }

                if (mediaArray.length > 0) {
                    setLoading(false);
                    return setMatchIs(true);
                }
                setLoading(false);
                return setMatchIs(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                return setMatchIs(false);
            })
    }, [id]);

    if (loading) {
        return <></>
    }
    if (matchIs) {
        return <>
            <div className='container bg-container-white'>
                <div className='App-div'>
                    <ProjectDetail projectId={id} />
                </div>
            </div>
            <div className='container-airbus bg-container-white'>
                <img
                    alt='logo logoAirbus-Cooper'
                    src={logoAirbus}
                />
            </div>
        </>
    } else {
        return <>
            <NoMatch />
            <div className='container-airbus-fixed'>
                <img
                    alt='logo logoAirbus-Cooper'
                    src={logoAirbus}
                />
            </div>
        </>
    }
}

function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();

    const refuse = () => {
        localStorage.removeItem('userAcj');
        localStorage.removeItem('pwdAcj');
        return <Navigate to={`${process.env.REACT_APP_ROOT_PATH}login`} state={{ from: location }} replace />;
    }

    const userLogged = localStorage.getItem('userAcj');
    const pwdLogged = localStorage.getItem('pwdAcj');

    if (!userLogged || !pwdLogged) {
        return refuse();
    }

    try {
        if (!process.env.REACT_APP_PASSWORD_ENCRYPTED || !process.env.REACT_APP_PASSWORD_DECRYPTED || !process.env.REACT_APP_IV) {
            return refuse();
        }
        const KEY = CryptoJS.enc.Hex.parse(pwdLogged);
        const IV = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);
        const PASSWORD_ENCRYPTED = CryptoJS.AES.encrypt(process.env.REACT_APP_PASSWORD_DECRYPTED, KEY, {
            iv: IV,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        if (PASSWORD_ENCRYPTED.toString() !== process.env.REACT_APP_PASSWORD_ENCRYPTED) {
            return refuse();
        }
    } catch (e) {
        return refuse();
    }

    return children;
}

function NoMatch() {
    return (
        <div className="container-2">
            <WrappedRoutes>
                <h2 className={'h1'}>Oops, Nothing to see here!</h2>
                <p>
                    <Link to={process.env.REACT_APP_ROOT_PATH ?? '/'}>Go to the home page</Link>
                </p>
            </WrappedRoutes>
        </div>
    );
}

export default App;
