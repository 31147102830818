import * as React from 'react';
import { PropsWithChildren } from "react";


const WrappedRoutes = ({ children }: PropsWithChildren) => {
    return (
        <main className="main-wrapped">
            { children }
        </main>
    )
}

export default WrappedRoutes;