import * as React from 'react';
import { PropsWithChildren } from "react";
import styled from "styled-components";


type ButtonProps = PropsWithChildren<{
    onClick?: () => void
    disabled: boolean
    type: "button" | "submit" | "reset" | undefined
    id?: string
}>

const Button = ({ children, onClick, disabled, type, id }: ButtonProps) => {

    const StyledButton = styled.button`
      text-align: center;
      background: ${props => props.disabled ? 'rgba(0, 0, 0, 0.12)' : "var(--primary)"};
      color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.26)' : 'white'};
      padding: calc(0.7rem - 1px) 1rem;
      border: none;
      border-radius: 3px;
      font-weight: 700;
      line-height: 1.5rem;
      font-size: .875rem;
      cursor: ${props => props.disabled ? 'auto' : 'pointer'};
      width: 100%;

      &:hover {
        background: ${props => props.disabled ? 'rgba(0, 0, 0, 0.12)' : "#6e4c34"};
      }`;

    return (
        <>
            <StyledButton className='mt-5' type={type} disabled={disabled}
                          id={id} onClick={onClick}>{children}</StyledButton>
        </>
    )
}


export default Button;
